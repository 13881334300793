$edge: 30px;

* {
	box-sizing: border-box;
}

body, html {
	padding: 0;
	margin: 0;
	overflow: hidden;
	background: #000;
	color: #fff;
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	font-size: 30px;
	letter-spacing: -0.02em;
	-webkit-font-smoothing: antialiased;
	
	@media screen and (max-width: 1300px) {
		font-size: 25px;
	}
}


body {
	padding: $edge;
}

.wrapper {
	opacity: 0;
}

.wrapper--visible {
	opacity: 1;
}

a {
	color: inherit;
	text-decoration: none;
	display: inline-block;
	border-bottom: 5px solid rgba(#fff, 0);
	line-height: 1em;
}

a:hover {
	border-bottom: 5px solid rgba(#000, 0.94);
}

.woman, .woman__dress {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.woman__dress {
	opacity: 0;
}

.woman {
	background-image: url('/assets/images/body--mia.jpg');
}

// .woman--blur {
// 	filter: blur(15px);
// }

.woman__dress {
	background-image: url('/assets/images/dress--mia.png');
}

.text {
	// display: flex;
	// justify-content: center;
	// align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	line-height: 1.3em;
	background: rgba(#e22d00, 1);
	background: #eaeaea;
	background: #da4e55;
	color: #000;
	opacity: 0;
	padding: $edge;
	padding-bottom: 0;
	pointer-events: none;
	overflow: scroll;
}

.info--visible {
	transform: translateZ(0);
	opacity: 1;
	pointer-events: auto;
}

.not-supported {
	opacity: 0;
}

.not-supported--visible {
	opacity: 1;
}

.text__inner {
	position: relative;
	min-height: 100%;
	padding-bottom: 140px;
}

.text__inner p {
	width: 80%;
	max-width: 1000px;
	min-width: 300px;
	margin: 0 0 35px 0;
	font-family: 'Cormorant Garamond', serif;
	font-weight: 300;
	font-size: 58px;
	line-height: 1.02em;
	cursor: default;
	// font-family: 'Cormorant', serif;
	// font-family: 'Lustria', serif;
	// font-family: 'Caudex', serif;
}

.not-supported__inner p {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	font-size: 30px;
	line-height: 1.25em;
}

.info__inner p:nth-child(2) {
	margin-bottom: 15px;
}


.obscure {
	text-decoration: line-through;
	cursor: not-allowed;
}

.obscure:hover {
	text-decoration: none;
}

sup {
	font-size: 20px;
	font-family: 'Work Sans', sans-serif;
	font-weight: 300;
}

// .italic {
// 	font-style: italic;
// 	font-weight: 100;
// }

.info__inner a {
	font-family: 'Work Sans', sans-serif;
	font-weight: 300;
	font-size: 44px;
}

.select {
	list-style-type: none;
	padding: 0;
	margin: 0;
	position: absolute;
	margin-top: 5px;
}

.select__option {
	cursor: pointer;
	opacity: 0.36;
	line-height: 1em;
	display: inline-block;
	// border-bottom: 5px solid rgba(#fff, 0);
	margin-bottom: 11px;
	// text-decoration: line-through;
	font-family: 'Cormorant Garamond', serif;
	font-size: 33px;
	font-weight: 500;
	width: 170px;
	-webkit-user-select: none;
}

.select__option--selected {
	opacity: 1;
	transform: translateX(28px);
	text-decoration: none;
	cursor: default;
}

.select__option:not(.select__option--selected):hover {
	// border-bottom: 5px solid rgba(#fff, 0.94);
	opacity: 1;
}

.about {
	position: absolute;
	cursor: pointer;
	-webkit-user-select: none;
	display: inline-block;
	line-height: 1em;
	border-bottom: 5px solid rgba(#fff, 0);
	opacity: 0.36;
	bottom: $edge;
}

.about--close {
	opacity: 1;
}

.about--close:hover {
	opacity: 1;
	border-bottom: 5px solid rgba(#000, 0.94);
}

.about--open:hover {
	opacity: 1;
	border-bottom: 5px solid rgba(#fff, 0.94);
}